import React, { useState } from 'react';
import { FormControl, Popper, Input, Grid, Avatar, InputLabel, Fade, CircularProgress, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import './Roles.css';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  avatar: {
    width: '25px',
    height: '25px'
  }
});

export default function UserRoleSuggestBox({ initialValue, onSelect, name, label }) {
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenOptions = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleCloseOptions = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (event) => {
    const currentValue = event.target.value;
    if (currentValue.length > 3) {
      setIsLoading(true);
      getUsersQuery(currentValue).then((users) => {
        setOptions(users);
        setIsLoading(false);
      });
    }
    setValue(currentValue);
  };

  const handleSelectUser = (user) => {
    setOptions([]);
    onSelect(user);
    setValue(user.fullname);
    handleCloseOptions();
  };

  const handleOnBlur = () => setTimeout(() => handleCloseOptions(), 150);
  return (
    <FormControl variant="standard" fullWidth onBlur={handleOnBlur}>
      <InputLabel>
        <FormattedMessage id="UserRoleSuggestBox.Label" defaultMessage="Select User" />
      </InputLabel>
      <Input
        onFocus={handleOpenOptions}
        value={value || ''}
        onChange={handleChange}
        endAdornment={isLoading ? <CircularProgress color="inherit" size={20} /> : null}
      />
      <OptionsContainer canShow={isOpen && !isLoading && !!options.length} anchorEl={anchorEl}>
        {options.map((option) => (
          <Option key={option.id} option={option} handleSelectUser={handleSelectUser} classes={classes} />
        ))}
      </OptionsContainer>
    </FormControl>
  );
}

function OptionsContainer({ anchorEl, children, canShow }) {
  return (
    <Popper open={canShow} anchorEl={anchorEl} style={{ zIndex: 2000 }}>
      <Fade in={canShow}>
        <Paper
          style={{
            width: anchorEl ? anchorEl.clientWidth : undefined,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '300px',
            padding: '10px',
            zIndex: 2000
          }}
        >
          {children}
        </Paper>
      </Fade>
    </Popper>
  );
}

function Option({ option, handleSelectUser, classes }) {
  return (
    <div className="UserSuggestBox__Option">
      <Grid container spacing={1} onClick={() => handleSelectUser(option)}>
        <Grid item>
          <Avatar className={classes.avatar} sizes="20px">
            {option.name[0]}
          </Avatar>
        </Grid>
        <Grid item xs>
          {option.name}
        </Grid>
      </Grid>
    </div>
  );
}
