import React, { Fragment } from 'react';
import './safetyInspection.css';
import {
  initSafetyInspection,
  loadQuestinaireTemplates,
  loadQuestinaireTemplateOnce
} from '@zert-packages/actions/checklistActions';
import { connect } from 'react-redux';
import { elementCreatedAndLoaded, loadHelp, API_ROOT_WAR, moveAfterDelivery } from '@zert-packages/actions/api';
import { withRouter } from 'react-router-dom';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import TaskMembersEditor from '@zert-packages/components/common/Task/TaskMembersEditor';
import DeadlineList from '@zert-packages/components/common/Task/DeadlineList';
import IntervalEditor from '@zert-packages/components/common/Task/IntervalEditor';
import moment from 'moment';
import CatalogPlacement from '@zert-packages/components/shared/CatalogPlacement/CatalogPlacement';
import CancelButton from '@zert-packages/components/shared/CallToActionButton/CancelButton';
import { FormattedMessage } from 'react-intl';
import { getOverviewSettings } from '@zert-packages/actions/coreReducers';
import ButtonRM from '@zert-packages/components/shared/Button/index';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import { stateToHTML } from 'draft-js-export-html';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import Scroll from '@zert-packages/components/shared/Scroll';
import Typography from '@mui/material/Typography';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import { TextField } from '@mui/material';

class TemplateSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <select
        style={{
          color: '#0E3944',
          fontWeight: '500',
          background: 'none',
          border: 'none',
          width: '100%',
          margin: '6px 0 0 -4px'
        }}
        list_id="5"
        onChange={(e) => {
          this.props.onChange(e);
        }}
        defaultValue={this.props.selected}
      >
        {this.props.questionairetemplates.map((template, index) => {
          return (
            <option key={index} value={template.versionId}>
              {template.name}
            </option>
          );
        })}
      </select>
    );
  }
}

class NewSafetyInspectionPage extends React.Component {
  constructor(props) {
    super(props);
    const value1 = localStorage.getItem('selectedLabel');
    const value2 = localStorage.getItem('selectedLabel2');

    this.state = {
      noTemplates: false,
      idtemplate: -1,
      name: '',
      description: null,
      remindDeadlineMappings: [],
      earlieststart: {
        amount: 3,
        unit: 'd'
      },
      participantMappings: [],
      siworkflowtemplate: null,
      multipleValue: getMultipleValue(this.props.catalog),
      multipleValue2: value2 ? [{ value: value2 }] : [],
      help: null
    };
    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectorChange = this.selectorChange.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.onEarliestStartChange = this.onEarliestStartChange.bind(this);
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.assignChagedValues2 = this.assignChagedValues2.bind(this);
  }

  assignChagedValues(data) {
    this.state.multipleValue = data;
    this.forceUpdate();
  }

  assignChagedValues2(data) {
    this.state.multipleValue = data;
    this.forceUpdate();
  }

  noTemplatesCallback = () => {
    this.setState({ noTemplates: true });
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (!this.props.catalogpermits['zert-safety-inspection']) {
      this.props.dispatch(getOverviewSettings('zert-safety-inspection'));
    }
    this.props.dispatch(loadQuestinaireTemplates(false, true, this.noTemplatesCallback));
    if (!this.props.helpText.safety_insp) {
      this.props.dispatch(loadHelp('safety_insp'));
    } else {
      this.setState({ help: this.props.helpText.safety_insp });
    }
    this.setState({
      versionId: params.versionId,
      activityId: params.activityid
    });
    const url = window.location.href;
    const parameters = new URLSearchParams(url.split('?')[1]);
    const templateVersionId = parameters.get('template-version-id');
    if (templateVersionId) {
      this.setState({
        idtemplate: templateVersionId
      })
    }
  }

  onClick(e) {
    if (this.state.name == null || this.state.name.length == 0 || this.props.siworkflowtemplate == null) {
      return;
    }
    if (this.state.idtemplate == -1) {
      this.setState({ idtemplate: this.props.questionairetemplates[0].versionId });
    }
    const safetyInspection = {
      name: this.state.name,
      description: this.state.description,
      earliestStartInterval: this.state.earlieststart
    };

    const safetyInspectionSummary = {
      safetyInspection,
      remindDeadlineMappings: this.state.remindDeadlineMappings,
      participantMappings: this.state.participantMappings
    };

    this.props.dispatch(
      initSafetyInspection(
        this.state.idtemplate == -1 ? this.props.questionairetemplates[0].versionId : this.state.idtemplate,
        safetyInspectionSummary,
        this.state.multipleValue,
        this.props.storeInExplorer && this.props.selectedTreeNode ? this.props.selectedTreeNode.id : null,
        this.state.versionId,
        this.state.activityId
      )
    );
  }

  selectorChange(e) {
    const templateId = e.target.value;
    const template = this.props.questionairetemplates.find((template) => e.target.value === `${template.versionId}`);

    this.setState(
      {
        idtemplate: templateId,
        defaultDescription: template && template.description ? getTranslation(template.description) : '',
        instruction: template && template.instruction ? getTranslation(template.instruction) : ''
      },
      () => {
        this.props.dispatch(loadQuestinaireTemplateOnce(templateId));
      }
    );
    // initCheckList(id);
  }

  componentDidUpdate(prevProps) {
    const basisinpath = window.location.href.indexOf('basis') >= 0;
    if (this.props.newElementCreated && !basisinpath) {
      this.props.history.push(`${API_ROOT_WAR}/safetyinspection/${this.props.newversionid}`);
      this.props.dispatch(elementCreatedAndLoaded());
    } else if (this.props.newElementCreated) {
      moveAfterDelivery(this.state.versionId, this.props.history, this.props.dispatch, this.state.activityId);
    } else if (
      (this.props.siworkflowtemplate != null && this.state.siworkflowtemplate == null) ||
      (this.props.siworkflowtemplate != null &&
        this.state.siworkflowtemplate != null &&
        this.props.siworkflowtemplate.versionId != this.state.siworkflowtemplate.versionId)
    ) {
      const remindDeadlineMappings = [];
      const participantMappings = [];
      this.props.siworkflowtemplate.deadlines.map((deadline) => {
        const remindDeadlineMapping = {
          identifier: deadline.id,
          name: deadline.name,
          interval: {
            amount: 7,
            unit: 'w'
          },
          deadline: moment().add(1, 'day').endOf('day'),
          mode: 'enter'
        };
        remindDeadlineMappings.push(remindDeadlineMapping);
      });

      this.props.siworkflowtemplate.participants.map((participant) => {
        const participantMapping = {
          workflowParticipant: participant,
          user: null
        };
        participantMappings.push(participantMapping);
      });

      this.setState({
        remindDeadlineMappings,
        participantMappings,
        siworkflowtemplate: this.props.siworkflowtemplate
      });
    } else if (this.state.help == null && this.props.helpText.safety_insp != null) {
      this.setState({ help: this.props.helpText.safety_insp });
    }

    if (
      this.props.questionairetemplates != null &&
      prevProps.questionairetemplates == null &&
      this.props.questionairetemplates.length > 0
    ) {
      const template = this.props.questionairetemplates[0];
      this.setState({
        idtemplate: this.props.questionairetemplates[0].versionId,
        defaultDescription: template && template.description ? getTranslation(template.description) : '',
        instruction: template && template.instruction ? getTranslation(template.instruction) : ''
      });
    }
  }

  onEarliestStartChange(unit, amount) {
    this.state.earlieststart.amount = amount;
    this.state.earlieststart.unit = unit;
  }

  handleChange(e) {
    this.setState({ name: e.target.value });
  }

  handleChangeDesc(e) {
    const html = stateToHTML(e.getCurrentContent());

    this.setState({ description: html });
  }

  render() {
    if (this.state.noTemplates) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '25px'
          }}
        >
          <Typography color="textPrimary" fontSize="18" variant="h5">
            <FormattedMessage id="NewSafetyInspectionPage.NoTemplatesMessage" defaultMessage="No templates!" />
          </Typography>
        </div>
      );
    }

    if (this.props.error != null) {
      return (
        <div>
          <FormattedMessage
            id="newsafetyinspection.error"
            defaultMessage="Error! {message}"
            values={{ message: this.props.error.message }}
          />
        </div>
      );
    }

    if (this.props.loadingTemplates || this.props.questionairetemplates == null) {
      return (
        <Loader>
          <FormattedMessage id="newsafetyinspection.loading" defaultMessage="Questionnaire templates" />
        </Loader>
      );
    }

    if (this.props.creatingNewElement) {
      return <Loader loaderAction={LoaderAction.Creating} />;
    }

    let catalogAllowedContinue = true;

    if (this.props.catalogpermits != null && this.props.catalogpermits['zert-safety-inspection']) {
      var object = this.props.catalogpermits['zert-safety-inspection'];
      var setuped1 = !object.catalogElementId1 || (object.catalogElementId1 && this.state.multipleValue.length > 0);

      if (object.catalogStatus == 'any') {
        if (this.state.multipleValue.length == 0) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus == 'and') {
        if (!setuped1 && object.catalogElementId1) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus == 'or') {
        if (!setuped1) {
          catalogAllowedContinue = false;
        }
      }
    }
    if (this.props.catalogpermits != null && this.props.catalogpermits['zert-safety-inspection']) {
      var object = this.props.catalogpermits['zert-safety-inspection'];
      var setuped1 = !object.catalogElementId1 || (object.catalogElementId1 && this.state.multipleValue.length > 0);
      const setuped2 = !object.catalogElementId2 || (object.catalogElementId2 && this.state.multipleValue2.length > 0);

      if (object.catalogStatus == 'any') {
        if (this.state.multipleValue.length == 0 && this.state.multipleValue2.length == 0) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus == 'and') {
        if (!setuped1 || !setuped2) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus == 'or') {
        if (!setuped1 && !setuped2) {
          catalogAllowedContinue = false;
        }
      }
    }

    return (
      <Scroll>
        <div className="workfield" style={{ height: 'unset' }}>
          <div
            className="left_panel"
            style={{
              flex: '1',
              backgroundColor: '#F4F8FA',
              padding: '0 0 0 24px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 'calc(var(--vh, 1vh) * 100 - 56px)'
            }}
          >
            <div
              className="editMember"
              style={{
                backgroundColor: '#F4F8FA',
                height: 'auto',
                boxShadow: 'none',
                flex: '10'
              }}
            >
              <div className="captionLabel">
                <FormattedMessage id="newsafetyinspection.title" defaultMessage="Safety inspection Title" />
                <span className="required">*</span>
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TextField
                  variant="standard"
                  autoFocus={!this.state.name}
                  fullWidth
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>

              {this.props.catalog && (
                <>
                  <div className="captionLabel">
                    <FormattedMessage
                      id="newsafetyinspection.placement"
                      defaultMessage="Placement in {catalog}"
                      values={{ catalog: this.props.catalog.name }}
                    />
                  </div>
                  <div className="edit" style={{ background: 'none' }}>
                    <CatalogPlacement
                      assignChagedValues={this.assignChagedValues}
                      multipleValue={this.state.multipleValue}
                      catalog={this.props.catalog}
                      loading={this.props.loadingCatalog}
                    />
                  </div>
                </>
              )}

              <div className="captionLabel">
                <FormattedMessage id="newsafetyinspection.template" defaultMessage=" Questionaire Template" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TemplateSelector
                  questionairetemplates={this.props.questionairetemplates}
                  selected={this.state.idtemplate}
                  onChange={this.selectorChange}
                />
              </div>

              {this.props.siworkflowtemplate != null &&
                this.state.participantMappings != null &&
                this.state.participantMappings.length > 0 && (
                  <TaskMembersEditor participantMappings={this.state.participantMappings} />
                )}

              {this.props.siworkflowtemplate != null &&
                this.state.remindDeadlineMappings != null &&
                this.state.remindDeadlineMappings.length > 0 && (
                  <DeadlineList deadlinemappings={this.state.remindDeadlineMappings} />
                )}

              <div className="captionLabel">
                <FormattedMessage
                  id="newsafetyinspection.earlieststart"
                  defaultMessage=" Earliest start date(before deadline)"
                />
              </div>
              <IntervalEditor interval={this.state.earlieststart} onChange={this.onEarliestStartChange} />

              <div className="captionLabel">
                <FormattedMessage id="newsafetyinspection.description" defaultMessage="  Additional Description" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <RichTextEditor
                  key={`autogrowInput${this.state.idtemplate}`}
                  text={this.state.defaultDescription}
                  onChange={this.handleChangeDesc}
                  onBlur={this.onDescBlur}
                />
              </div>
            </div>

            <div
              style={{
                flex: '1',
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                className="createNewButtons"
                style={{
                  display: 'flex',
                  padding: '20px 0',
                  position: 'sticky',
                  bottom: '0px'
                }}
              >
                <ButtonRM
                  styleName="primary"
                  whenClick={this.onClick}
                  disabled={this.state.name === null || this.state.name.length === 0 || !catalogAllowedContinue}
                  text=""
                >
                  <FormattedMessage id="newsafetyinspection.create" defaultMessage="Create" />
                </ButtonRM>

                <CancelButton />
              </div>
            </div>
          </div>

          <div
            id="right_panel"
            style={{
              flex: '1',
              padding: '24px'
            }}
          >
            {this.state.help && (
              <div
                className="checklistDesc"
                dangerouslySetInnerHTML={{ __html: this.state.help ? this.state.help : '' }}
              />
            )}
            {this.state.instruction && (
              <div className="checklistDesc">
                <div className="checklistNewDescHeader">
                  <FormattedMessage id="newpage.safetyInspection.instruction" defaultMessage="Instruction" />
                </div>
                <div
                  className="checklistNewDescSubHeader"
                  dangerouslySetInnerHTML={{
                    __html: this.state.instruction ? this.state.instruction : ''
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Scroll>
    );
  }
}

const mapStateToProps = (state) => ({
  helpText: state.helpText,
  loadingTemplates: state.loadingTemplates,
  questionairetemplates: state.questionairetemplates,
  creatingNewElement: state.creatingNewElement,
  newElementCreated: state.newElementCreated,
  newversionid: state.newversionid,
  loadingCatalog: Object.values(state.loadingCatalogs)
    .reduce((a, v) => a || v, false),
  siworkflowtemplate: state.siworkflowtemplate,
  catalog: state.catalog,
  selectedTreeNode: state.CORE.selectedNode,
  catalogpermits: state.catalogpermits
});

export default withRouter(connect(mapStateToProps)(NewSafetyInspectionPage));
