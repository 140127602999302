import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import './style.css';
import Spacer from '@zert-packages/components/shared/Spacer/index';
import DeadlinePicker from '@zert-packages/components/shared/DeadlinePicker/DeadlinePicker';
import { stateToHTML } from 'draft-js-export-html';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import {
  loadSafetyInspection,
  storeSafetyInspection,
  generateSingleSafetyInspectionReport,
  loadChecklist,
  performSafetyInspection
} from '@zert-packages/actions/checklistActions';
import { connect } from 'react-redux';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import { openReport, API_ROOT_WAR, cleanMeasureError } from '@zert-packages/actions/api';
import { FormattedMessage } from 'react-intl';

import Modal from '@zert-packages/components/shared/Modal/Modal';
import { withRouter } from 'react-router-dom';

import { verifyPasswordPost } from '@zert-packages/actions/measureActions';

import moment from 'moment';
import { Button } from '@mui/material';
import { IntroductionContext } from '@zert-packages/components/common/Introduction';
import DisabledElementMode from '@zert-packages/components/common/DisabledElementMode';
import TabsWithVersionController from '@zert-packages/components/common/TabsWithVersionController';
import TaskWorkflowView from '@zert-packages/components/TabTask/TaskWorkflowView';
import ChecklistsPage from './ChecklistsPage';
import OriginView from '../../view/OriginView/OriginView';
import DeadlinePage from './DeadlinePage';
import WorkflowStageButtons from './WorkflowStageButtons';
import { TabMeasures } from "rm/src/view/TabMeasures/TabMeasures";
import isNotCheckedOutByMe from "@zert-packages/utils/isNotCheckedOutByMe";
import SafetyInspectionReports from './SafetyInspectionReports';
class SafetyInspectionView extends React.Component {
  static contextType = IntroductionContext;

  constructor(props) {
    super(props);
    this.state = {
      idCheckList: -1,
      activeTab: 0,
      report: null,
      isConfirmPasword: false,
      isOpenSafetyInspection: false,
      isOpenTransition: false,
      store_element: false,
      isModified: false
    };
    this.switchTab = this.switchTab.bind(this);
    this.onChangeDateCallback = this.onChangeDateCallback.bind(this);
    this.onChange = this.onChange.bind(this);
    this.estimateTask = this.estimateTask.bind(this);
    this.performAndFillIn = this.performAndFillIn.bind(this);
    this.onWorkFlowActivityDone = this.onWorkFlowActivityDone.bind(this);

    this.toggleOwnerConfirmPasword = this.toggleOwnerConfirmPasword.bind(this);
    this.toggleConfirmPasword = this.toggleConfirmPasword.bind(this);

    this.confirmOwnerConfirmPasword = this.confirmOwnerConfirmPasword.bind(this);
    this.confirmConfirmPasword = this.confirmConfirmPasword.bind(this);
    this.storeSafetyInspection = this.storeSafetyInspection.bind(this);

    this.errorOk = this.errorOk.bind(this);
    this.errorCancel = this.errorCancel.bind(this);
    this.getActivity = this.getActivity.bind(this);
    this.markAsModified = this.markAsModified.bind(this);
    this.ownerpassword = React.createRef();
    this.password = React.createRef();
  }

  errorOk() {
    this.props.dispatch(cleanMeasureError());
  }

  errorCancel() {
    this.props.dispatch(cleanMeasureError());
  }

  markAsModified() {
    if (!this.state.isModified) {
      this.setState({ isModified: true });
    }
  }

  onWorkFlowActivityDone(transition) {
    if (transition.passwordRequired && !this.state.passwordConfirmed) {
      this.setState({
        isConfirmPasword: true,
        transition
      });
    } else if (transition.ownerPasswordRequired) {
      this.setState({
        isOwnerConfirmPasword: true,
        transition
      });
    } else {
      this.setState(
        {
          transition,
          performingSI: true
        },
        () => {
          this.props.dispatch(
            performSafetyInspection(this.props.safetyinspection.versionId, this.getActivity(transition).id)
          );
        }
      );
    }
  }

  //  this.props.onWorkflowClickDone(transition, this.props.measure)

  toggleOwnerConfirmPasword = () => {
    this.setState({
      isOwnerConfirmPasword: !this.state.isOwnerConfirmPasword
    });
  };

  toggleConfirmPasword = () => {
    this.setState({
      isConfirmPasword: !this.state.isConfirmPasword
    });
  };

  confirmOwnerConfirmPasword = () => {
    this.setState({
      isOwnerConfirmPasword: !this.state.isOwnerConfirmPasword
    });
  };

  confirmConfirmPasword = () => {
    verifyPasswordPost(this.password.value)
      .then((result) => {
        if (result) {
          this.setState(
            {
              isConfirmPasword: !this.state.isConfirmPasword,
              passwordConfirmed: true
            },
            () => {
              this.onWorkFlowActivityDone(this.state.transition);
            }
          );
        } else {
          this.setState({
            showPasswordError: true
          });
        }
      })
      .catch((err) => {});
  };

  getActivity(transition) {
    return this.props.workflowtemplate.activities.find((activity) => {
      return activity.id === transition.to.id;
    });
  }

  onChange(editorState) {
    const html = stateToHTML(editorState.getCurrentContent());
    this.props.safetyinspection.description = html;
    this.markAsModified()
  }

  onChangeDateCallback(date) {
    this.props.safetyinspection.currentActivityDeadline = date;
    this.props.safetyinspection.remindDeadlineMappings.map((remindDeadlineMapping) => {
      if (remindDeadlineMapping.identifier === this.props.safetyinspection.currentActivity.remindDeadline) {
        remindDeadlineMapping.deadline = this.props.safetyinspection.currentActivityDeadline;
      }
    });
    this.markAsModified()
  }

  componentWillMount() {
    this.unlisten = this.props.history.block(() => {
      this.storeSafetyInspection();
    });
  }

  forceSave() {
    this.storeSafetyInspection(true)
  }

  storeSafetyInspection(forceSave = false) {
    let shouldSave = forceSave || (this.state.isModified && this.props.safetyinspection)
    if (shouldSave) {
      const safetyInspection = {
        name: this.props.safetyinspection.name,
        description: this.props.safetyinspection.description,
        earliestStartInterval: this.props.safetyinspection.earliestStartInterval
      };

      const remindDeadlineMappings = [];
      const participantMappings = [];

      this.props.safetyinspection.remindDeadlineMappings.map((deadline) => {
        const remindDeadlineMapping = {
          identifier: deadline.identifier,
          name: deadline.name,
          interval: {
            amount: deadline.interval.amount,
            unit: deadline.interval.unit
          },
          deadline: deadline.deadline,
          mode: deadline.mode
        };
        remindDeadlineMappings.push(remindDeadlineMapping);
      });

      this.props.safetyinspection.participantMappings.map((participant) => {
        const participantMapping = {
          workflowParticipant: participant.workflowParticipant,
          user: participant.user
        };
        participantMappings.push(participantMapping);
      });

      const safetyInspectionSummary = {
        safetyInspection,
        remindDeadlineMappings,
        participantMappings
      };

      this.props.dispatch(storeSafetyInspection(this.props.safetyinspection.versionId, safetyInspectionSummary));
      this.setState({ isModified: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.context.searchTab !== null && this.state.activeTab !== this.context.searchTab) {
      this.setState({ activeTab: this.context.searchTab });
      this.context.setSearchTab(null);
    }
    if (!prevProps.currentchecklist && this.props.currentchecklist &&
        this.props.versionInfo && prevProps.versionInfo &&
        this.props.versionInfo.info.versionId === prevProps.versionInfo.info.versionId &&
        prevProps.safetyinspection &&
        this.props.currentActivityId !== prevProps.currentActivityId) {
      this.props.history.push(`${API_ROOT_WAR}/checklist/${this.props.currentchecklist.versionId}/0/0`);
    }

    const {
      match: { params }
    } = this.props;
    const idCheckList = params.versionid;
    if (
      this.props.report != null &&
      this.props.report_done &&
      this.state.report &&
      this.props.report.reportUUID === this.state.report.reportUUID
    ) {
      this.setState({ report: null }, () => {
        window.open(`${openReport + this.props.report.reportUUID}/report/pdf`);
      });
    } else if (this.state.idCheckList !== idCheckList) {
      this.setState({ idCheckList }, () => {
        this.setState({ loading: false });
        this.props.dispatch(getVersionInfo(idCheckList));
        this.props.dispatch(loadSafetyInspection(idCheckList));
      });
    }
    if (this.props.safetyinspection && this.props.safetyinspection !== prevProps.safetyinspection) {
      if (this.state.performingSI && this.props.currentchecklist) {
        this.props.history.push(`${API_ROOT_WAR}/checklist/${this.props.currentchecklist.versionId}/0/0`);
      }
      if (this.props.currentchecklist) {
        this.props.dispatch(loadChecklist(this.props.currentchecklist.versionId, true));
      }
    }

    if (
      prevProps != null &&
      prevProps.store_element &&
      !this.props.store_element &&
      this.state.transition != null &&
      this.state.understore
    ) {
      this.setState(
        {
          understore: false,
          performingSI: true
        },
        () => {
          this.props.dispatch(
            performSafetyInspection(this.props.safetyinspection.versionId, this.getActivity(this.state.transition).id)
          );
        }
      );
    }

    /* if(this.props.checklist && this.props.checklist !== prevProps.checklist) {
      this.estimateTask();
    } */
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const idCheckList = getParameterAsInt(params.versionid);

    this.setState({ idCheckList });
    if (this.props.mytasks) {
      this.props.mytasks.map((task) => {
        if (task.versionId === idCheckList) {
          task.visited = true;
        }
      });
    }
    this.props.dispatch(getVersionInfo(params.versionid));
    this.props.dispatch(loadSafetyInspection(params.versionid, true));
  }

  switchTab(object) {
    this.setState({
      activeTab: object
    });
  }

  estimateTask() {
    const size = Object.keys(this.props.checklist.answerAltIds).length;

    if (size > 20 && size <= 40) {
      this.setState({ estimate: 15 });
    } else if (size > 40) {
      this.setState({ estimate: 30 });
    } else {
      this.setState({ estimate: 5 });
    }
  }

  //   {!report_gen && <img src={"/" + Worker} />}

  performAndFillIn(e) {
    if (this.props.currentchecklist) {
      var transition = this.props.workflowtemplate.transitions.find((t, index) => {
        return t.from.id === this.props.safetyinspection.currentActivity.id;
      });
      if (transition && transition.performOnFill) {
        this.onWorkFlowActivityDone(transition);
      } else {
        this.props.history.push(`${API_ROOT_WAR}/checklist/${this.props.currentchecklist.versionId}/0/0`);
      }
    } else {
      var transition = this.props.workflowtemplate.transitions.find((t, index) => {
        return t.from.id === this.props.safetyinspection.currentActivity.id;
      });
      if (transition) {
        this.onWorkFlowActivityDone(transition);
      }
    }
  }

  render() {
    if (this.props.error != null) {
      return (
        <div>
          <FormattedMessage id="safetyinspectionsummary.error" defaultMessage="Error..." /> {this.props.error.message}
        </div>
      );
    }

    if (this.props.loadingsafetyinspection || !this.props.safetyinspection) {
      return <Loader />;
    }

    const { currentActivityDeadline } = this.props.safetyinspection;
    const isLocked = this.props.myuser ?
      isNotCheckedOutByMe(this.props.versionInfo, this.props.myuser) :
      true;
    let allowToEdit = true;
    let allowToWork =
      this.props.versionInfo &&
      this.props.versionInfo.info.approvedBy == null &&
      this.props.versionInfo.info.archivedBy == null &&
      this.props.versionInfo.info.versionId === this.props.versionInfo.info.currentVersionId;

    if (
      !(
        this.props.permits['zert-questionnaire/create-safety-inspection'] &&
        this.props.permits['zert-questionnaire/create-safety-inspection'] === true
      ) &&
      !(
        this.props.permits['zert-questionnaire/edit-safety-inspection'] &&
        this.props.permits['zert-questionnaire/edit-safety-inspection'] === true
      )
    ) {
      allowToWork = false;
      allowToEdit = false;
    }

    let dateless = currentActivityDeadline ? moment(currentActivityDeadline) : null;
    if (currentActivityDeadline != null && this.props.safetyinspection.earliestStartInterval) {
      let subtractUnit = 'days';
      if (this.props.safetyinspection.earliestStartInterval.unit === 'd') {
        subtractUnit = 'days';
      }
      if (this.props.safetyinspection.earliestStartInterval.unit === 'w') {
        subtractUnit = 'weeks';
      }

      if (this.props.safetyinspection.earliestStartInterval.unit === 'M') {
        subtractUnit = 'months';
      }

      if (this.props.safetyinspection.earliestStartInterval.unit === 'y') {
        subtractUnit = 'years';
      }

      dateless = moment(currentActivityDeadline).subtract(
        subtractUnit,
        this.props.safetyinspection.earliestStartInterval.amount
      );
      if (dateless > moment()) {
        allowToEdit = false;
      }
    }

    const latestChecklist = this.props.currentchecklist ||
        (this.props.safetyinspection.checkLists.length > 0
            ? this.props.safetyinspection.checkLists
                .reduce((a, b) => Date.parse(a.createdAt) > Date.parse(b.createdAt) ? a : b)
            : undefined);

    let tabsList = [
      {
        label: <FormattedMessage id="safetyinspection.content" defaultMessage="Content" />,
        styleName: 'btn',
        analytics: 'content'
      },
      {
        label: <FormattedMessage id="safetyinspection.description" defaultMessage="Description" />,
        styleName: 'btn',
        analytics: 'description'
      },

      {
        label: <FormattedMessage id="safetyinspection.checklists" defaultMessage="Checklists" />,
        styleName: 'btn',
        analytics: 'checklists'
      },
      {
        label: <FormattedMessage id="safetyinspection.info" defaultMessage="Info" />,
        styleName: 'btn',
        analytics: 'info'
      },
      {
        label: <FormattedMessage id="checklist.measures" defaultMessage="Measures" />,
        styleName: 'btn',
        analytics: 'measures'
      },
      {
        label: <FormattedMessage id="safetyinspection.team" defaultMessage="Team" />,
        styleName: 'btn',
        analytics: 'team'
      },
      {
        label: <FormattedMessage id="safetyinspection.workflow" defaultMessage="Workflow" />,
        styleName: 'btn',
        analytics: 'workflow'
      }
    ];

    if (!allowToWork) {
      tabsList = [
        {
          label: <FormattedMessage id="safetyinspection.content" defaultMessage="Content" />,
          styleName: 'btn',
          analytics: 'content'
        },
        {
          label: <FormattedMessage id="safetyinspection.description" defaultMessage="Description" />,
          styleName: 'btn',
          analytics: 'description'
        },
        {
          label: <FormattedMessage id="safetyinspection.checklists" defaultMessage="Checklists" />,
          styleName: 'btn',
          analytics: 'checklists'
        },
        {
          label: <FormattedMessage id="safetyinspection.info" defaultMessage="Info" />,
          styleName: 'btn',
          analytics: 'info'
        },
        {
          label: <FormattedMessage id="checklist.measures" defaultMessage="Measures" />,
          styleName: 'btn',
          analytics: 'measures'
        }
      ];
    }
    const {
      match: { params }
    } = this.props;
    const report_gen =
      this.props.report_generation_started &&
      this.props.report_callback &&
      this.state.report &&
      this.props.report &&
      this.props.report.reportUUID === this.state.report?.reportUUID;
    const idCheckList = getParameterAsInt(params.versionid);

    const getInstructionByLocale = (instruction) => {
      let translation = instruction.defaultLabel;

      const indexOfLocale = instruction.locales.indexOf(instruction);
      if (indexOfLocale !== -1) {
        translation = instruction.translations[indexOfLocale];
      }

      return translation;
    }

    const participantMappings = this.props.safetyinspection.participantMappings;
    // <NavLink exact activeClassName="current" to={API_ROOT_WAR + "/checklist/" + currentchecklist.versionId + "/0/0"}></NavLink>
    return (
      <DisabledElementMode>
        <div className="content-cage checklist-start">
          <Modal show={this.props.store_element}>
            <Loader loaderAction={LoaderAction.Storing} />
          </Modal>
          <Modal show={this.props.measure_performing}>
            <Loader loaderAction={LoaderAction.Performing} />
          </Modal>

          {this.props.perform_measure_error && (
            <Modal show={this.props.perform_measure_error} onClose={this.errorCancel} onOK={this.errorOk}>
              <div dangerouslySetInnerHTML={{ __html: this.props.perform_measure_error.message }} />
            </Modal>
          )}

          <Modal
            show={this.state.isOwnerConfirmPasword}
            onClose={this.toggleOwnerConfirmPasword}
            onOK={this.confirmOwnerConfirmPasword}
          >
            The task owner, {this.state.transition != null && this.state.transition.name}, need to enter the password to
            perform the action {this.state.transition != null && this.state.transition.name} on the following tasks.
            <input ref={this.ownerpassword} type="password" />
          </Modal>

          <Modal
            show={this.state.isConfirmPasword}
            onClose={this.toggleConfirmPasword}
            onOK={this.confirmConfirmPasword}
          >
            You need to enter your password to perform the action{' '}
            {this.state.transition != null && this.state.transition.name} on the following tasks.
            <div style={this.state.showPasswordError && { backgroundColor: 'red' }}>
              <input ref={this.password} type="password" />
            </div>
          </Modal>

          <TabsWithVersionController
            tabs={tabsList}
            tabIndex={this.state.activeTab}
            onChangeTabIndex={(e, value) =>
              this.setState({
                activeTab: value
              })
            }
            canApproveVersion={false}
            canSaveVersion={!this.props.versionInfo.info.approvedAt &&
              !this.props.versionInfo.info.archivedAt &&
              !isLocked}
            canCreateVersion={false}
            onSaveVersion={() => this.forceSave()}
            onCreateNewVersion={() => {}}
            onApproveVersion={() => {}}
            canShowNextTask
            versionId={getParameterAsInt(params.versionid)}
          />
          {this.state.activeTab === 0 && (
            <div className="content-group align-center" isBlock>
              <div className="worker-cage" />
              <div className="text-box">
                <h2 className="title" data-searchable-element="1">
                  {this.props.safetyinspection.name}
                </h2>
                <Spacer />
                <div data-intro-id="SafetyInspectionView.Date" className="group justify-center">
                  <div style={{ paddingRight: '0.5em' }}>
                    <FormattedMessage id="safetyinspection.startdate" defaultMessage="Earliest start date:" />
                  </div>
                  <DeadlinePicker
                    deadline={dateless ? dateless.toDate() : null}
                    readonly
                    onChangeCallback={this.onChangeDateCallback}
                  />
                </div>
                <Spacer />
                <div data-intro-id="SafetyInspectionView.WorkflowButtons" className="vertical-group align-center">
                  {allowToWork && allowToEdit && !isLocked && (
                    <>
                      <Button
                        data-intro-id="SafetyInspectionView.FillButton"
                        color="primary"
                        variant="contained"
                        style={{textTransform: 'none', fontSize: '1.1rem'}}
                        disabled={!this.props.currentchecklist}
                        onClick={() => {
                          if (this.props.currentchecklist && this.props.currentchecklist.versionId) {
                            this.props.history.push(`${API_ROOT_WAR}/checklist/${this.props.currentchecklist.versionId}/0/0`);
                          }
                        }}
                      >
                        <FormattedMessage id="safetyinspection.fillchecklits" defaultMessage="Open Current Checklist" />
                      </Button>

                      <Spacer />
                    </>
                  )}
                  {this.props.workflowtemplate && allowToEdit && !isLocked && (
                    <WorkflowStageButtons workflowtemplate={this.props.workflowtemplate} />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '10px'
                    }}
                  >
                    {!report_gen && (
                      <SafetyInspectionReports
                        checklist={latestChecklist}
                        safetyInspectionId={idCheckList}
                      />
                    )}
                    {report_gen && (
                      <Loader loaderAction={LoaderAction.None}>
                        {' '}
                        {this.props.report_callback.message && this.props.report_callback.message}
                      </Loader>
                    )}
                  </div>
                </div>
              </div>
                {this.props.questionairetemplate && this.props.questionairetemplate.instruction &&
                  this.props.questionairetemplate.instruction.defaultLabel && (
                  <div className="checklistInstruction">
                    <div className="checklistDescContainer">
                      <div className="checklistDesc">
                        <div className="checklistNewDescHeader">
                          <FormattedMessage id="newpage.checklist.instruction" defaultMessage="Instruction" />
                        </div>
                        <div
                          className="checklistNewDescSubHeader"
                          dangerouslySetInnerHTML={{
                            __html: getInstructionByLocale(this.props.questionairetemplate.instruction)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
          {this.state.activeTab === 1 && (
            <RichTextEditor
              text={this.props.safetyinspection.description}
              onChange={this.onChange}
              readonly={!allowToWork || isLocked}
            />
          )}

          {this.state.activeTab === 2 && <ChecklistsPage checklists={this.props.safetyinspection.checkLists} isBlock />}
          {this.state.activeTab === 5 && allowToWork && (
            <DeadlinePage
              task={this.props.safetyinspection}
              participantMappings={participantMappings}
              isBlock
              disabled={isLocked}
              remindDeadlineMappings={this.props.safetyinspection.remindDeadlineMappings}
              earlieststart={this.props.safetyinspection.earliestStartInterval}
              onModification={this.markAsModified}
            />
          )}
          {this.state.activeTab === 3 && <OriginView />}
          {this.state.activeTab === 4 &&
            <TabMeasures versionId={this.props.versionInfo.info.versionId} />}
          {this.state.activeTab === 6 && <TaskWorkflowView versionId={idCheckList} onModification={this.markAsModified}/>}

          {this.context.safetyInspection.component}
          {this.context.safetyInspection.startIntro()}
        </div>
      </DisabledElementMode>
    );
  }
}

const mapStateToProps = (state) => ({
  workflowtemplate: state.siworkflowtemplate,
  currentActivityId: state.safetyinspection?.currentActivity.id,
  checklist: state.checklist,
  loading: state.loading,
  error: state.error,
  report_error: state.report_error,
  report_generation_started: state.report_generation_started,
  report: state.report,
  report_callback: state.report_callback,
  report_done: state.report_done,
  myuser: state.myuser,
  mytasks: state.mytasks,
  versionInfo: state.versionInfo,
  questionairetemplate: state.questionairetemplate,
  store_element: state.store_element,
  measure_performing: state.measure_performing,
  storedriskrow: state.storedriskrow,
  safetyinspection: state.safetyinspection,
  currentchecklist: state.safetyinspection?.checkLists?.find((checklist) => {
    return checklist.name === 'Current';
  }),
  perform_measure_error: state.perform_measure_error,
  permits: state.permits
});

export default withRouter(connect(mapStateToProps)(SafetyInspectionView));
