import React, { Fragment } from 'react';
import './style.css';
import {
  clearDeviationTemplate,
  createDeviation,
  initDeviation,
  loadDeviationTemplate,
  loadDeviationTemplates
} from '@zert-packages/actions/deviationActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import ButtonRM from '@zert-packages/components/shared/Button/index';
import InlineDeviationEditor, {
  submitHasAllMandatoryData
} from '@zert-packages/components/common/FormEditor/InlineDeviationEditor';
import Spacer from '@zert-packages/components/shared/Spacer/index';
import { API_ROOT_WAR, elementCreatedAndLoaded, loadHelp, moveAfterDelivery } from '@zert-packages/actions/api';
import CatalogPlacement from '@zert-packages/components/shared/CatalogPlacement/CatalogPlacement';
import CancelButton from '@zert-packages/components/shared/CallToActionButton/CancelButton';
import { FormattedMessage } from 'react-intl';
import { getOverviewSettings } from '@zert-packages/actions/coreReducers';
import Scroll from '@zert-packages/components/shared/Scroll';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import getTranslation from '@zert-packages/utils/getTranslation.old';

class TemplateSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <select
        defaultValue={this.props.selected}
        style={{
          color: '#0E3944',
          fontWeight: '500',
          background: 'none',
          border: 'none',
          width: '100%',
          margin: '6px 0 0 -4px'
        }}
        list_id="5"
        onChange={(e) => {
          this.props.onChange(e);
        }}
      >
        {this.props.deviationtemplates.map((template, index) => {
          return (
            <option key={index} value={template.versionId}>
              {template.name}
            </option>
          );
        })}
      </select>
    );
  }
}

class NewDeviationPage extends React.Component {
  constructor(props) {
    super(props);
    const value1 = localStorage.getItem('selectedLabel');
    const value2 = localStorage.getItem('selectedLabel2');
    this.state = {
      idtemplate: -1,
      name: '',
      description: null,
      deviation: null,
      deviationtemplate: null,
      rows: [],
      isAddRisk: false,
      isAddRiskSave: false,
      multipleValue: getMultipleValue(this.props.catalog),
      multipleValue2: value2 ? [{ value: value2 }] : [],
      nodes: [],
      help: null
    };
    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectorChange = this.selectorChange.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.cloneRows = this.cloneRows.bind(this);
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.assignChagedValues2 = this.assignChagedValues2.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.checkCatalogStatus = this.checkCatalogStatus.bind(this);
  }

  assignChagedValues(data) {
    this.state.multipleValue = data;
    this.forceUpdate();
  }

  assignChagedValues2(data) {
    this.state.multipleValue2 = data;
    this.forceUpdate();
  }

  cloneRows(rows) {
    const rowscloned = [];
    rows.map((row) => {
      const clonerow = { ...row };
      rowscloned.push(clonerow);
    });
    return rowscloned;
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.props.dispatch(clearDeviationTemplate());
    this.props.dispatch(loadDeviationTemplates());

    if (!this.props.catalogpermits['zert-deviation']) {
      this.props.dispatch(getOverviewSettings('zert-deviation'));
    }

    if (!this.props.helpText.deviation) {
      this.props.dispatch(loadHelp('deviation'));
    } else {
      this.setState({ help: this.props.helpText.deviation });
    }
    this.setState({
      versionId: params.versionId,
      activityId: params.activityid
    });
    const url = window.location.href;
    const parameters = new URLSearchParams(url.split('?')[1]);
    const templateVersionId = parameters.get('template-version-id');
    if (templateVersionId) {
      this.setState(
        {
          idtemplate: templateVersionId,
          deviationtemplate: null
        },
        () => {
          this.props.dispatch(loadDeviationTemplate(templateVersionId));
        }
      );
    }
  }

  onClick(e) {
    this.props.dispatch(
      initDeviation(
        this.state.idtemplate == -1 ? this.props.deviationtemplates[0].versionId : this.state.idtemplate,
        this.state.deviation,
        this.state.multipleValue,
        this.props.storeInExplorer && this.props.selectedTreeNode ? this.props.selectedTreeNode.id : null,
        this.state.versionId,
        this.state.activityId
      )
    );
  }

  selectorChange(e) {
    const template = e.target.value;
    this.setState(
      {
        idtemplate: template,
        deviationtemplate: null
      },
      () => {
        this.props.dispatch(loadDeviationTemplate(template));
      }
    );
  }

  componentDidUpdate(prevProps) {
    const basisinpath = window.location.href.indexOf('basis') >= 0;
    if (this.props.newElementCreated && !basisinpath) {
      this.props.history.push(`${API_ROOT_WAR}/action-plan`);
      this.props.dispatch(elementCreatedAndLoaded());
    } else if (this.props.newElementCreated) {
      moveAfterDelivery(this.state.versionId, this.props.history, this.props.dispatch, this.state.activityId);
    } else if (
      this.props.deviationtemplate != null &&
      this.state.deviationtemplate == null &&
      (this.state.idtemplate == -1 || this.state.idtemplate == this.props.deviationtemplate.versionId)
    ) {
      const deviation = createDeviation(this.props.deviationtemplate, null);
      const deviationTemplate = this.props.deviationtemplate;
      this.setState({
        deviation,
        deviationtemplate: deviationTemplate,
        description:
          deviationTemplate && deviationTemplate.description ? getTranslation(deviationTemplate.description) : ''
      });
    } else if (this.state.help == null && this.props.helpText.deviation != null) {
      this.setState({ help: this.props.helpText.deviation });
    }
  }

  handleChange(e) {
    this.setState({ name: e.target.value });
  }

  onUpdate = (deviation) => {
    this.setState({ deviation });
  };

  handleChangeDesc(e) {
    this.setState({ description: e.target.value });
  }

  checkCatalogStatus(catalogPermits, multipleValue) {
    if (!catalogPermits || !catalogPermits['zert-deviation']) return true;

    const object = catalogPermits['zert-deviation'];
    const hasSetup1 = !object.catalogElementId1 || (object.catalogElementId1 && multipleValue.length > 0);

    switch (object.catalogStatus) {
      case 'any':
        return multipleValue.length > 0;
      case 'and':
        return hasSetup1;
      case 'or':
        return hasSetup1;
      default:
        return true;
    }
  }

  render() {
    if (this.props.error != null) {
      return (
        <div>
          <FormattedMessage
            id="newpage.deviation.error"
            defaultMessage="Error!, {message}"
            values={{ message: this.props.error.message }}
          />
        </div>
      );
    }
    if (this.props.loadingTemplates || this.props.deviationtemplates == null) {
      return (
        <Loader>
          <FormattedMessage id="newpage.deviation.loading" defaultMessage="Submit templates" />
        </Loader>
      );
    }

    if (this.props.creatingNewElement) {
      return <Loader loaderAction={LoaderAction.Creating} />;
    }

    let catalogAllowedContinue = this.checkCatalogStatus(this.props.catalogpermits, this.state.multipleValue);

    catalogAllowedContinue =
      catalogAllowedContinue &&
      this.state.deviationtemplate &&
      this.state.deviation &&
      submitHasAllMandatoryData(
        this.state.deviationtemplate,
        this.state.deviation,
        this.state.deviationtemplate.columnActivityDisplay,
        { taskAdmin: true, currentActivity: { id: this.state.deviationtemplate.startActivityId } }
      );

    return (
      <Scroll>
        <div className="main">
          <div className="caption" />
          <div className="workfield" style={{ height: 'unset' }}>
            <div
              className="left_panel"
              style={{
                flex: '1',
                backgroundColor: '#F4F8FA',
                padding: '0 0 0 24px',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(var(--vh, 1vh) * 100 - 56px)'
              }}
            >
              <div
                className="editMember"
                style={{
                  backgroundColor: '#F4F8FA',
                  height: 'auto',
                  boxShadow: 'none',
                  flex: '10'
                }}
              >
                <div className="captionLabel">
                  <FormattedMessage id="newpage.deviation.template" defaultMessage="Deviation Template" />
                </div>
                <div className="edit" style={{ background: 'none' }}>
                  <TemplateSelector
                    deviationtemplates={this.props.deviationtemplates}
                    onChange={this.selectorChange}
                    selected={this.state.idtemplate}
                  />
                </div>

                {this.props.catalog && (
                  <>
                    <div className="captionLabel">
                      <FormattedMessage
                        id="newpage.deviation.page"
                        defaultMessage="Placement in {catalog}"
                        values={{ catalog: this.props.catalog.name }}
                      />
                      <span className="required">*</span>
                    </div>
                    <div className="edit" style={{ background: 'none' }}>
                      <CatalogPlacement
                        catalog={this.props.catalog}
                        assignChagedValues={this.assignChagedValues}
                        multipleValue={this.state.multipleValue}
                        loading={this.props.loadingCatalog}
                      />
                    </div>
                  </>
                )}

                {this.state.deviationtemplate != null && this.state.deviation != null && (
                  <InlineDeviationEditor
                    deviation={this.state.deviation}
                    user={this.props.myuser}
                    task={{ taskAdmin: true, currentActivity: { id: this.state.deviationtemplate.startActivityId } }}
                    onUpdate={this.onUpdate}
                    deviationtemplate={this.state.deviationtemplate}
                  />
                )}
                <Spacer />
              </div>

              <div
                style={{
                  flex: '1',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div className="createNewButtons">
                  <ButtonRM styleName="primary" whenClick={this.onClick} disabled={!catalogAllowedContinue} text="">
                    <FormattedMessage id="newpage.deviation.create" defaultMessage="Submit Report" />
                  </ButtonRM>
                  <CancelButton />
                </div>
              </div>
            </div>

            <div
              id="right_panel"
              style={{
                flex: '1',
                padding: '24px'
              }}
            >
              {this.state.help && <div dangerouslySetInnerHTML={{ __html: this.state.help ? this.state.help : '' }} />}
              {this.props.deviationtemplate && (
                <div className="checklistDesc">
                  <div className="checklistNewDescHeader">
                    <FormattedMessage id="newpage.deviation.description" defaultMessage="Description" />
                  </div>
                  <div
                    className="checklistNewDescSubHeader"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description ? this.state.description : ''
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Scroll>
    );
  }
}

const mapStateToProps = (state) => ({
  helpText: state.helpText,
  loadingTemplates: state.loadingTemplates,
  deviationtemplates: state.deviationtemplates,
  deviationtemplate: state.deviationtemplate,
  creatingNewElement: state.creatingNewElement,
  newElementCreated: state.newElementCreated,
  newversionid: state.newversionid,
  riskrowtemplate: state.template,
  riskrow: state.riskrow,
  error: state.error,
  catalog: state.catalog,
  loadingCatalog: Object.values(state.loadingCatalogs).reduce((a, v) => a || v, false),
  myuser: state.myuser,
  selectedTreeNode: state.CORE.selectedNode,
  catalogpermits: state.catalogpermits
});

export default withRouter(connect(mapStateToProps)(NewDeviationPage));
